import { stringEmpty } from '~/utils/helpers'

export default function () {
  /**
   * Helper function to get data from front door API
   * @param frontDoorRelative - Relative path to front door API endpoint
   * @param postProcessing - Optional function to process data before returning
   */
  const fetch = async <Payload, Result = Payload>(
    frontDoorRelative: string,
    postProcessing = (data: Payload) => data as unknown as Result
  ) => {
    if (stringEmpty(frontDoorRelative)) return

    const url = getUrl(frontDoorRelative)

    const data = await $fetch<Payload>(url, {
      headers: {
        accept: 'application/vnd.api+json'
      }
    })

    return postProcessing(data)
  }

  /**
   * Helper function to call GraphQL data from FrontDoor API
   * @param graphQlId - GraphQL ID to query
   * @param postProcessing - Optional function to process data before returning
   */
  const fetchGraphQl = async <Payload, Result = Payload>(
    graphQlId: string,
    postProcessing = (data: Payload) => data as unknown as Result
  ) => {
    if (stringEmpty(graphQlId)) return

    return fetch(`/api/ssc/e2e.gql/query?id=${graphQlId}`, postProcessing)
  }

  /**
   * Helper function to get image url from front door API
   * @param path - Relative path to image
   */
  const getImgUrl = (path: string) => {
    if (stringEmpty(path)) return ''

    return getUrl(`/api/-/media/Images/${path}`)
  }

  const getPdfUrl = (path: string) => {
    if (stringEmpty(path)) return ''

    return getUrl(`/api${path}`)
  }
  /**
   * Helper function to return front door API with relative path
   * @param frontDoorRelative - Relative path to front door API endpoint
   */
  const getUrl = (frontDoorRelative: string) => {
    const config = useRuntimeConfig().public

    const frontDoorApiUrl = config.frontDoorApiUrl

    return `${frontDoorApiUrl}${frontDoorRelative}`
  }

  return { fetch, fetchGraphQl, getImgUrl, getPdfUrl }
}
