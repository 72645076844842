import { defineStore } from 'pinia'
import useFrontDoorApi from '~/composables/useFrontDoorApi'

interface State {
  items: Record<string, unknown>
}

export const useSitecoreGraphQueryStore = defineStore('sitecoreGraphQuery', {
  state: (): State => ({
    items: {}
  }),

  getters: {
    hasEntry: (state) => (id: string) => Object.keys(state.items).includes(id)
  },

  actions: {
    async fetch<T>(
      id: string,
      additionalParams?: Record<string, string | number | boolean>
    ) {
      if (this.hasEntry(id)) return this.items[id] as T

      const { fetch } = useFrontDoorApi()

      let payload: T | undefined = undefined

      // not an ideal solution, but don't want to extend the 'useFrontDoorApi' composable to accept
      // additional params object for middleware api because middleware api is on the way out
      if (additionalParams) {
        const query = `${Object.entries(additionalParams)
          .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
          .join('&')}`

        payload = await fetch<T>(`/api/ssc/e2e.gql/query?id=${id}&${query}`)
      } else {
        payload = await fetch<T>(`/api/ssc/e2e.gql/query?id=${id}`)
      }

      if (payload) {
        // add params to id to create a unique key
        if (additionalParams !== undefined) {
          id = `${id}-${Object.entries(additionalParams)
            .map(([key, val]) => `${key}_${val}`)
            .join('-')}`
        }

        this.items[id] = payload
        return payload as T
      }

      return {} as T
    }
  }
})
